.main-panel {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0;
}

.Content {
    background-color: $blanco;
    width: 100%;
    height: 100vh;
    top: 0;
    overflow: auto;
    margin: 0 auto;
}

.bd-container {
    max-width: 1024px;
    width: calc(100% - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
}

.bd-grid {
    display: grid;
    gap: 1.5rem;
}

.section {
    padding: 4rem 0 2rem;
    margin-top: 6rem;
}

.section-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 22px;
    h2 {
        font-size: 2rem;
        color: #141c3a;
        text-align: center;
    }
    .section-icon {
        width: 80px;
        margin-right: 12px;
    }
    .section-subtitle {
        font-size: 1.1rem;
        font-weight: 300;
        color: $grisMedio;
        position: relative;
        vertical-align: middle;
        font-family: $Roboto;
        text-align: center;
    }
}

/****** ABOUT *****/
.about {
    background-color: #ffffff;
    position: relative;
    margin-top: 80px;
    @include media-768px {
        margin-top: 40px;
    }
    .Avatar {
        border-radius: 50%;
        width: 96px;
        height: 96px;
        border: 4px solid #6d76f7;
    }
    img {
        width: 320px;
        height: auto;
        margin: 0 auto;
        position: relative;
        display: flex;
        top: 1px;
        @include media-480px {
            width: 230px;
        }
    }
    .info {
        width: 100%;
        height: 270px;
        background-color: #742af7;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        margin-bottom: 16px;
        @include media-1024px {
        }
        h3 {
            color: $blanco;
            text-align: center;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 2;
            b {
                color: $naranja;
                text-transform: none;
            }
            @include media-480px {
                font-size: 1.4rem;
            }
            @include media-480px {
                line-height: normal;
                margin-bottom: 8px;
                font-size: 1.2rem;
            }
        }
        p {
            color: $blanco;
            width: 30%;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 300;
            line-height: 1.4;
            margin: 0 auto;
            position: relative;
            @include media-1024px {
                width: 50%;
            }
            @include media-768px {
                width: 70%;
            }
            @include media-680px {
                width: 90%;
            }
            @include media-480px {
                font-size: 0.9rem;
            }
        }
    }

    .about__container {
        padding: 70px 110px;
        width: 100%;
        height: auto;
        @include media-768px {
            padding: 92px 40px;
        }
        .about__data {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .about__description {
                h1 {
                    color: $negro;
                    font-size: 2rem;
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 12px;
                }
                h2 {
                    color: $negro;
                    font-size: 2rem;
                    font-weight: 200;
                    text-align: center;
                    margin-top: 12px;
                    @include media-1024px {
                        font-size: 1.7rem;
                    }
                    @include media-768px {
                        font-size: 1.4rem;
                    }
                }

                .about__button {
                    border: 1px solid #00b0ff;
                    background-color: #00b0ff;
                    padding: 6px 18px;
                    border-radius: 22px;
                    color: #ffffff;
                    font-size: 0.9rem;
                    font-weight: 400;
                    outline: none;
                    text-align: center;
                    cursor: pointer;
                    &:hover {
                        background-color: #fff;
                        color: #00b0ff;
                        border: 1px solid #00b0ff;
                        transform: scale(1.01);
                        box-shadow: 0 3px 2px darkgray;
                    }
                    &:active {
                        border: 1px solid #00b0ff;
                        background-color: #00b0ff;
                        color: #ffffff;
                    }
                }
            }
            .about__image {
                width: 30%;
                height: auto;
                margin: 0 auto;
                position: relative;
            }
        }
    }
}

/****** PORTFOLIO *****/
.portfolio {
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: transparent;
    background-image: url("../images/svg/bgstarts.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    .portfolio__container {
        padding: 28px 10px;
        .portfolio__content {
            background-color: #fff;
            border-radius: 0.5rem;
            overflow: hidden;
            box-shadow: 0 4px 6px rgba(174, 190, 205, 0.3);

            .portfolio__img {
                width: 100%;
                transition: 0.4s;
            }

            .portfolio__data {
                padding: 1.5rem;

                .portfolio__subtitle {
                    font-size: 0.813rem;
                    color: $gris;
                }
                .portfolio__title {
                    font-size: 0.92rem;
                    font-weight: 300;
                    line-height: 1.3;
                    color: grisPurple;
                    margin: 0.8rem 0;

                    @include media-680px {
                        font-size: 0.84rem;
                        line-height: normal;
                    }
                }
                .button-link {
                    font-size: 0.8em;
                    color: $blue;
                    margin: 1rem 0;
                    text-decoration: none;
                    font-weight: 400;
                    &:hover {
                        color: $greenBurn;
                        text-decoration: underline;
                    }

                    .about__icon {
                        margin: 0 auto;
                        position: relative;
                        top: 7px;
                        right: 6px;
                    }
                }
            }

            &:hover {
                box-shadow: 0 6px 8px rgba(174, 190, 205, 0.4);
            }
            &:hover,
            .portfolio__img {
                transform: scale(1.02);
                transition: 0.3s;
            }
        }
    }
}

/****** QUALIFICATION *****/
.qualification {
    padding: 42px 12px 12px 12px;
    background-color: $gray;
    .qualification__container {
        .qualification__content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .qualification__title {
                font-size: $--h3-font-size;
                color: $greenBurn;
                margin-bottom: $--mb-2;
                .qualification__title-icon {
                    font-size: 1.5rem;
                    margin-right: $--mb-1;
                    top: 4px;
                    position: relative;
                }
            }
            .qualification_icon_content {
                background-color: $blanco;
                .qualification_icon {
                    color: $grayBrown;
                    font-size: 1.6rem;
                }
            }
        }
    }
}

.qualification_time {
    padding: 12px 12px 0 12px;
    margin-top: 5rem;
    background-color: #ffffff;

    .qualification__title_time {
        font-size: $--h3-font-size;
        color: $greenBurn;
        margin-bottom: $--mb-2;

        .qualification__title-icon_time {
            font-size: 1.5rem;
            margin-right: $--mb-1;
            top: 4px;
            position: relative;
        }
    }
    .qualification__icon_time {
        color: $white;
    }
}

/****** QUALIFICATION  / EDUCATION*****/
.GridContent {
    display: "flex";
    justify-content: "center";
    align-items: center;
    margin: 0 auto;
    .GridList {
        width: 50%;
        height: 100%;
        overflow: "hidden";
    }

    .GridIcon {
        color: rgba(255, 255, 255, 0.54);
    }
}

/****** SERVICES *****/
.services {
    padding: 12px;
    background-color: #f9faff;
    padding-bottom: 22px;
    .services__container {
        display: flex;
        padding: 12px;
        .services__data {
            padding: 1.2rem 1rem;
            background-color: #fff;
            text-align: center;
            transition: 0.4s;
            box-shadow: 0 4px 6px rgba(51, 53, 54, 0.3);
            .services__icon_content {
                box-shadow: 0 4px 6px rgba(51, 53, 54, 0.3);
                border-radius: 50%;
                width: 55px;
                height: 55px;
                background-color: #742af8;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                margin-bottom: 12px;
                .services__icon {
                    font-size: 2rem;
                    position: relative;
                    text-align: center;
                    color: $white;
                    top: 0;
                }
            }
            .services__img {
                width: 160px;
                height: auto;
                margin: 0 auto;
            }
            .services__title {
                margin-bottom: 1rem;
                color: #141c3a;
                font-size: 1.2rem;
                font-weight: 300;
            }
            .services_description {
                margin-bottom: 2rem;
                font-size: 0.9rem;
                line-height: 1.5;
                color: $grisMedio;
            }

            &:hover {
                transform: translateY(-0.5rem);
                box-shadow: 0 6px 8px rgba(174, 190, 205, 0.4);
            }
        }
    }
}

/*****FOOTER******/
//#118cf1
.footer {
    background-color: #742af7;
    color: $blanco;
    text-align: center;
    width: 100%;
    height: auto;
    margin: 0 auto;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 40px;
    .footer__container {
        padding: 3rem 0;
        .footer__description {
            margin-bottom: 1rem;
            @include media-576px {
                padding: 12px;
            }
        }
        .footer__title {
            font-size: $--h1-font-size;
            margin-bottom: $--mb-1;
            font-weight: $--font-semi-bold;
        }

        .footer__social {
            margin-bottom: 1rem;
            .footer__link {
                font-size: 1.4rem;
                color: $--first-color-lighten;
                margin: 0 0.5rem;
                transition: 0.3s;
                &:hover {
                    color: $greenBrown;
                }
            }
        }
        .footer__copy {
            font-size: $--smaller-font-size;
            color: #fff;
            .footer__icon {
                color: $rojoMibo;
                font-size: 0.9rem;
                margin-left: 6px;
                top: 3px;
                position: relative;
            }
        }
    }

    .button_cv {
        @extend %webapp_button_main;
        background-color: #118cf1;
        border: 2px solid #118cf1;
        font-size: 1rem;
        font-weight: 500;
        width: 100px;
        position: relative;
        top: -10px;
        box-shadow: 1px 3px 1px 1px rgba(0, 0, 210, 0.2);

        &:hover {
            border: 2px solid $greenLigth;
            background: none;
            font-weight: 500;
            color: $greenLigth;
        }
        &:active {
            background-color: none;
            transition: 0.1s;
        }
    }
    .mentions__content {
        margin-top: 4rem;
        width: 50%;
        height: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @include media-576px {
            width: 100%;
        }
        small,
        p {
            font-size: 0.8rem;
            margin-left: 6px;
            margin-right: 6px;
            @include media-576px {
                font-size: 0.7rem;
            }
        }
    }
}

@include media-768min-px {
    body {
        margin: 0;
    }
    .section {
        padding-top: 3rem;
    }

    .project__container {
        padding: 4.5rem 0;
    }
    .project__data {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .project__icon,
    .project__title,
    .project__description {
        margin-bottom: 0;
    }
    .project__icon {
        font-size: 6rem;
    }
    .project__title {
        font-size: 2.5rem;
    }
}

@include media-1024min-px {
    .bd-container {
        margin-left: auto;
        margin-right: auto;
    }
}
