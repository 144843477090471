/* #5d50fe */
.NavBarItems {
    background-color: #742af7;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    position: fixed;
    padding: 1rem calc((90vw - 1000px) / 2);
}

.Navbar__logo {
    cursor: pointer;
    width: 120px;
    padding: 6px 8px;
    display: flex;
    position: relative;
    z-index: 9999;
}

.Navbar__icon {
    width: 70px;
    top: 22px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    animation: ovni 2s ease-in-out infinite alternate;
    transform-origin: center;
    transform-box: fill-box;
}
@keyframes ovni {
    from {
        transform: translateY(0%) rotateZ(0deg);
    }
    to {
        transform: translateY(24px) rotateZ(-2deg);
    }
}

.Nav__menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    text-align: center;
    width: 80%;
}

.Nav__links {
    color: #fff;
    text-decoration: none;
    padding: 1rem 0.7rem;
    font-size: 1rem;
    font-weight: 400;
    margin-right: 6px;
    margin-left: 6px;
}

.Nav__links:hover {
    background-color: #6d76f7;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}
.Nav__links__mobile {
    display: none;
}

.faBars {
    color: #fff;
}

.Menu__icon {
    display: none;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 980px) {
    .Nav__menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 50vh;
        position: absolute;
        top: 50px;
        left: -100%;
        opacity: 1;
        transition: all 0.2s ease;
        padding-top: 20px;
    }
    .Nav__menu > li {
        padding: 8px 10px;
    }

    .Nav__links {
        font-size: 1rem;
        font-weight: 400;
        height: 100px;
    }
    .Nav__menu.active {
        background: #742af7;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .Nav__links:hover {
        background-color: #7577fa;
        border-radius: 0;
    }
    .Navbar__logo {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(8px, 50px);
    }
    .Menu__icon {
        display: block;
        position: absolute;
        top: -5px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    @keyframes ovni {
        from {
            transform: translateY(0%) rotateZ(0deg);
        }
        to {
            transform: translateY(20px) rotateZ(-2deg);
        }
    }

    .Nav__links__mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }
    .Navbar__icon {
        top: -20px;
    }
}

@media screen and (max-width: 680px) {
    .Navbar__icon {
        top: -20px;
    }
}
@media screen and (max-width: 425px) {
    .Navbar__icon {
        top: -10px;
    }
}

.button_cv {
    @extend %webapp_button_main;
    background-color: #118cf1;
    border: 2px solid #118cf1;
    font-size: 1rem;
    font-weight: 500;
    width: auto;
    position: relative;
    top: 0;
    box-shadow: 1px 3px 1px 1px rgba(0, 0, 210, 0.2);
    margin-right: 8px;

    &:hover {
        border: 2px solid #118cf1;
        background: none;
        font-weight: 500;
        color: $greenLigth;
    }
    &:active {
        background-color: none;
        transition: 0.1s;
    }
}
