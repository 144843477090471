.Home {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #000000;
    background-image: url("../images/svg/moon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-680px {
        background-position: center;
        background-size: cover;
    }

    .home__content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h1 {
            color: $blanco;
            font-size: 3rem;
            font-weight: 600;
            line-height: 1;
            strong {
                color: #742af7;
            }
        }
        h2 {
            line-height: 1.2;
            font-size: 3rem;
            color: $bluePurple;
        }
        p {
            color: $blanco;
            font-size: 1rem;
            width: 480px;
            line-height: 1.3;
            margin-top: 12px;
            text-align: left;
        }
        @include media-680px {
            padding: 12px;

            h1 {
                font-size: 2.4rem;
            }
        }
        @include media-576px {
            h1 {
                font-size: 2rem;
            }
        }
        @include media-480px {
            h1 {
                font-size: 2.6rem;
                text-align: center;
            }
        }
        @include media-380px {
            h1 {
                font-size: 2.4rem;
            }
        }
        @include media-280px {
            h1 {
                font-size: 1.9rem;
            }
        }

        .alien_icon {
            width: 40px;
            top: 6px;
            position: relative;
        }

        .formHome {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            @include media-480px {
                flex-wrap: nowrap;
                flex-direction: column;
            }

            .BtnStyleBurn {
                padding: 8px 16px;
                border-radius: 22px;
                color: $blanco;
                font-size: 0.9rem;
                border: 2px solid $bluePurple;
                font-weight: 400;
                outline: none;
                text-align: center;
                cursor: pointer;
                box-shadow: 0 0 0 0 rgba(250, 257, 255, 0.7);
                animation: peffect 2s infinite cubic-bezier(0.66, 0, 0, 1);
                background-color: $bluePurple;
                margin-top: 16px;
                position: relative;
                text-decoration: none;
                @keyframes peffect {
                    to {
                        box-shadow: 0 0 0 30px rgba(255, 249, 250, 0);
                    }
                }
                &:hover {
                    border: 2px solid $bluePurple;
                    background: none;
                    font-weight: 500;
                    color: $bluePurple;
                }
                &:active {
                    background-color: none;
                    transition: 0.1s;
                }
                @include media-576px {
                    margin-top: 22px;
                }
            }

            .input-field {
                width: 280px;
                background-color: #f0f0f0;
                margin: 10px 0;
                height: 38px;
                border-radius: 55px;
                padding: 8px 16px;
                position: relative;
                display: flex;
                margin-right: 20px;
                top: 7px;
                @include media-576px {
                    width: 230px;
                }

                @include media-380px {
                    margin-right: 0;
                    margin: 0 auto;
                    margin-top: 16px;
                }
                input {
                    background: none;
                    outline: none;
                    border: none;
                    line-height: 1;
                    font-weight: 600;
                    font-size: 1.1rem;
                    color: #333;
                    width: 100%;
                    &:placeholder-shown {
                        color: #aaa;
                        font-weight: 500;
                    }
                }
            }

            span {
                color: $rojoMibo;
                text-align: left;
                font-size: 16px;
                width: 60%;
                position: relative;
                @include media-480px {
                    margin-top: 20px;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}
