@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}

/*fonts*/

$Roboto: "Roboto", sans-serif;

/*colores*/
$bluePurple: #6d41fe;
$blue: #2175f5;
$white: #ffffff;
$green: #038572;
$greenLigth: #00dfbf;
$greenBurn: #00a990;
$greenBrown: #2a444c;
$gray: #dddddd;
$grayBrown: #6f6f6f;
$background: #eff1f9;
$blancosuave: #ebedf0;

$grisPurple: #58588d;
$grisMedio: #97999b;
$grisClaro: #c7c8c6;
$grisObscuro: #4e4e4e;
$Claro: #f0f0f0;
$rojoMibo: #e3002b;
$rojomibo: #e4002b;
$azul: #4512fd;
$azul_gris: #c9d0f6;
$morado: #4512fd;
$morado2: #4f0aff;
$morado_claro: #eff1fc;
$morado_clarotrans: rgba(35, 9, 129, 0.5);
$negro: #000000;
$blanco: #ffffff;
$gris: #67686d;
$naranja: #ffaf00;
$azul_masclaro: #f1f5fe;
$verde: #63df53;
$borde: 1px solid rgba(204, 204, 204, 0.18);
$borde_btn: 1px solid rgba(204, 204, 204, 0.3);

$--header-height: 3rem;

/********  Colors ********/

$--first-color: #3e0e12;
$--first-color-dark: #2f0a0d;
$--text-color: #524748;
$--first-color-light: #7b6f71;
$--first-color-lighten: #fbf9f9;

/**** Font and typography  *******/

$--body-font: "Roboto", sans-serif;
$--biggest-font-size: 2.5rem;
$--h1-font-size: 1.5rem;
$--h2-font-size: 1.25rem;
$--h3-font-size: 1.125rem;
$--normal-font-size: 0.938rem;
$--small-font-size: 0.875rem;
$--smaller-font-size: 0.813rem;

/***** Font weight *******/

$--font-medium: 500;
$--font-semi-bold: 600;
$--font-bold: 700;

/***** Margenes ****/
$--mb-1: 0.5rem;
$--mb-2: 1rem;
$--mb-3: 1.5rem;
$--mb-4: 2rem;
$--mb-5: 2.5rem;
$--mb-6: 3rem;

/****** Z INDEX ******/

$--z-normal: 1;
$--z-tooltip: 10;
$--z-fixed: 100;

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

a {
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transition-property: color, border-color, background-color;
    transition-property: color, border-color, background-color;
    font-family: $Roboto;
    outline: none;

    &:hover {
        -webkit-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
        -webkit-transition-duration: 300ms;
        transition-duration: 300ms;
        -webkit-transition-property: color, border-color, background-color;
        transition-property: color, border-color, background-color;
        font-family: $Roboto;
        outline: none;
    }
}

p {
    font-family: $Roboto;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

h1,
h2,
h3,
h4,
h5 {
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    font-family: $Roboto;
}

button {
    outline: none;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    font-family: $Roboto;
}

div,
img {
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    font-family: $Roboto;
    outline: none;
}

strong {
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    font-family: $Roboto;
}

.Pink {
    background-color: #f169be;
    color: $blanco;
}
.Purple {
    background-color: #9678e1;
    color: $blanco;
}
.Green {
    background-color: #3ec2df;
    color: $blanco;
}
.Orange {
    background-color: #fea31e;
    color: $blanco;
}
.Blue {
    background-color: #507bff;
    color: $blanco;
}
.Yellow {
    background-color: #ffce56;
    color: $blanco;
}
.Fiusha {
    background-color: #ff6384;
    color: $blanco;
}

@mixin media-1024min-px {
    @media screen and (min-width: 1024px) {
        @content;
    }
}
@mixin media-780px {
    @media screen and (min-width: 780px) {
        @content;
    }
}

@mixin media-768min-px {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin media-1024px {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin media-970px {
    @media screen and (max-width: 970px) {
        @content;
    }
}

@mixin media-768px {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin media-680px {
    @media screen and (max-width: 680px) {
        @content;
    }
}
@mixin media-576px {
    @media screen and (max-width: 576px) {
        @content;
    }
}

@mixin media-480px {
    @media screen and (max-width: 480px) {
        @content;
    }
}
@mixin media-380px {
    @media screen and (max-width: 380px) {
        @content;
    }
}
@mixin media-280px {
    @media screen and (max-width: 280px) {
        @content;
    }
}

%webapp_button_main {
    background-color: $bluePurple;
    border: 2px solid $bluePurple;
    padding: 6px 16px 6px 16px;
    border-radius: 5px;
    color: $blanco;
    font-size: 1rem;
    font-weight: 600;
    outline: none;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(250, 257, 255, 0.7);
    text-decoration: none;
    &:hover {
        border: 2px solid $bluePurple;
        background: none;
        font-weight: 500;
        color: $bluePurple;
    }
    &:active {
        background-color: none;
        transition: 0.1s;
    }
    %Icon_main {
        font-size: 1.2rem;
        position: relative;
        top: 5px;
        margin-left: 4px;
    }
}
