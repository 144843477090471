#MainCurva {
    width: 100%;
    height: auto;
    top: 40px;
    position: relative;
    margin: 0 auto;
    @include media-1024px {
        top: -90px;
    }
    @include media-768px {
        top: -80px;
    }
}
.Push {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    top: 0;
    left: 0;
    height: 100px;
    margin-bottom: 50px;
    background: none;
    position: relative;
}
.Wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    top: 0;
    left: 0;
    height: 60px;
    margin-bottom: 25px;
    background: none;
    position: relative;
}
.divider {
    width: 100%;
    height: 1px;
    background-color: $grisClaro;
    position: relative;
    text-align: center;
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.Moons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50px;
    img {
        width: 140px;
    }
}