

.Navegation {
    width: 260px;
    height: 100vh;
    background-color: $blancosuave;
    display: flex;
    flex-direction: column;
    left: 0;
    top: 0;
    position: relative;
    margin: 0;

    @include media-576px {
        display: none;
    }

    .navLogo {
        width: 100%;
        height: 180px;
        background-color: $blancosuave;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 10px;

        img {
            width: 40%;
            height: auto;
            margin: 0 auto;
            position: relative;
            border-radius: 50%;
            border: 4 px solid #507bff;
        }
        h4 {
            color: $grayBrown;
            font-size: 1rem;
            font-weight: 400;
        }
        .line {
            width: 80%;
            height: 1px;
            margin-top: 8px;
            background-color: #507bff;
        }
    }

    .navLinks {
        width: 100%;
        padding-left: 18px;
        li {
            list-style: none;
        }
        .LinkPost {
            display: flex;
            justify-content: baseline;
            align-items: center;
            line-height: 2rem;
            height: 50px;
            cursor: pointer;
            margin-top: 14px;
            margin-bottom: 14px;
            padding-left: 18px;
            text-decoration: none;
            border-radius: 22px 0 0 22px;
            list-style: none;
            &:hover {
                background-color: rgba($color: #e0dede, $alpha: 0.8);
                color: $blanco;
                transition: 0.2s;
                border-right: 3px solid #507bff;
            }

            .Icon {
                border-radius: 50%;
                width: 36px;
                height: 36px;
                padding: 7px;
                font-size: 1.5rem;
                top: -2px;
                position: relative;
                margin-right: 12px;
            }

            p {
                color: $grayBrown;
                font-size: 0.8rem;
                margin-left: 5px;
            }
        }
    }
}

.Active {
    background-color: rgba($color: #e0dede, $alpha: 0.8);
    color: $blanco;
    transition: 0.2s;
    border-right: 3px solid #507bff;
}
