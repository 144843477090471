.webapp__icon {
    @extend %Icon_main;
}

.landing__icon {
    @extend %Icon_main;
}

.webapp_button {
    @extend %webapp_button_main;
}

.landing__button {
    @extend %webapp_button_main;
    background-color: $rojoMibo;
    border: 2px solid $rojoMibo;
    &:hover {
        border: 2px solid $rojoMibo;
        color: $rojoMibo;
    }
}

.landing__button_pages {
    @extend %webapp_button_main;
    background-color: $negro;
    border: 2px solid $negro;
    &:hover {
        border: 2px solid $negro;
        color: $negro;
    }
}

.webapp__weex {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.webapp__memorama {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.webapp__realtime {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.landing__mibo {
    width: 100%;
    height: 820px;
    overflow: hidden;
    background-color: transparent;
    background-image: url("../images/landing/landing-mibo.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 120px 100px;
}

.landing__mibo_img {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.webapp__container {
    position: relative;
    margin: 0 auto;
    .webapp_description {
        padding: 12px 16px;

        .webapp_title {
            font-weight: bold;
            font-size: 1.6rem;
            color: $bluePurple;
        }
        .webapp_texts {
            font-size: 0.9rem;
            font-weight: 400;
            color: $grisMedio;
            margin-top: 12px;
        }
        .webapp_tecnologys {
            font-size: 1rem;
            color: $grayBrown;
            margin-top: 12px;
            margin-bottom: 12px;
            font-weight: 600;

            ul {
                margin-left: 18px;
                margin-top: 12px;
                li {
                    color: $grisMedio;
                    font-size: 0.9rem;
                    font-weight: 400;
                }
            }
        }
    }
}

.landing__container {
    position: relative;
    margin: 0 auto;
    .landing__description {
        padding: 12px 16px;

        .landing__title {
            font-weight: bold;
            font-size: 1.6rem;
            color: $rojoMibo;
        }
        .landing__texts {
            font-size: 0.9rem;
            font-weight: 400;
            color: $grisMedio;
            margin-top: 12px;
        }
        .landing__tecnologys {
            font-size: 1rem;
            color: $grayBrown;
            margin-top: 12px;
            margin-bottom: 12px;
            font-weight: 600;

            ul {
                margin-left: 18px;
                margin-top: 12px;
                li {
                    color: $grisMedio;
                    font-size: 0.9rem;
                    font-weight: 400;
                }
            }
        }
    }
}

.crm__container {
    position: relative;
    margin: 0 auto;
    .crm__description {
        padding: 12px 16px;

        .crm__title {
            font-weight: bold;
            font-size: 1.6rem;
            color: $rojoMibo;
        }
        .crm__texts {
            font-size: 0.9rem;
            font-weight: 400;
            color: $grisMedio;
            margin-top: 12px;
        }
        .crm__tecnologys {
            font-size: 1rem;
            color: $grayBrown;
            margin-top: 12px;
            margin-bottom: 12px;
            font-weight: 600;

            ul {
                margin-left: 18px;
                margin-top: 12px;
                li {
                    color: $grisMedio;
                    font-size: 0.9rem;
                    font-weight: 400;
                }
            }
        }
    }
    .crm__mibo_img {
        width: 100%;
        margin: 0 auto;
        position: relative;
    }
    .crm__img_grid {
        p {
            font-size: 12px;
            margin-left: 8px;
        }
        img {
            width: 100%;
            height: auto;
            margin: 0 auto;
            position: relative;
        }
    }
}

.bot__container {
    position: relative;
    margin: 0 auto;
    .bot__description {
        padding: 12px 16px;

        .bot__title {
            font-weight: bold;
            font-size: 1.6rem;
            color: $rojoMibo;
        }
        .bot__texts {
            font-size: 0.9rem;
            font-weight: 400;
            color: $grisMedio;
            margin-top: 12px;
        }
        .bot__tecnologys {
            font-size: 1rem;
            color: $grayBrown;
            margin-top: 12px;
            margin-bottom: 12px;
            font-weight: 600;

            ul {
                margin-left: 18px;
                margin-top: 12px;
                li {
                    color: $grisMedio;
                    font-size: 0.9rem;
                    font-weight: 400;
                }
            }
        }
    }
    .bot__mibo_img {
        width: 100%;
        margin: 0 auto;
        position: relative;
    }
    .bot__img_grid {
        .bot__img_content {
            img {
                width: 80%;
                height: auto;
                margin: 0 auto;
                position: relative;
            }
        }
    }
}

.cv__main {
    width: 100%;
    height: auto;
    padding: 60px 100px;

    @include media-1024px {
        padding: 50px 30px;
    }
    @include media-380px {
        padding: 50px 12px;
    }
    .cv__title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @include media-970px {
            flex-direction: column;
        }
        h1 {
            position: relative;
            text-align: center;
            @include media-380px {
                font-size: 1.7rem;
            }
        }
        img {
            position: relative;
            width: 40px;
            top: 8px;
            left: 12px;
            transform: rotate(-15deg);
        }
    }
    .cv__profile {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        position: relative;
        @include media-970px {
            flex-direction: column;
        }
        @include media-380px {
            font-size: 0.9rem;
        }
    }

    .cv__description {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.4;
        text-align: justify;
        @include media-380px {
            font-size: 0.9rem;
        }
    }
    .cv__data {
        ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: baseline;
            @include media-970px {
                flex-direction: column;
                align-items: center;
            }
            @include media-380px {
                align-items: flex-start;
            }
            li {
                display: flex;
                list-style: none;
                padding: 10px 20px;
                margin: 10px 20px;
                @include media-970px {
                    padding: 5px 10px;
                }
                @include media-380px {
                    padding: 0;
                    margin: 4px 0;
                }
                svg {
                    display: flex;
                    margin-right: 12px;
                    color: $bluePurple;
                }
                p {
                    position: relative;
                    top: 4px;
                    font-size: 0.9rem;
                }
            }
        }
    }
    .cv__line {
        width: 100%;
        height: 3px;
        background: rgb(184, 26, 184);
        background: linear-gradient(135deg, rgba(184, 26, 184, 1) 37%, rgba(0, 209, 255, 1) 100%);
        margin-bottom: 12px;
        margin-top: 12px;
    }

    .cv__main__content {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        @include media-970px {
            flex-direction: column;
            justify-content: center;
        }
        .cv__content {
            width: 30%;
            @include media-970px {
                width: 100%;
            }
            h4 {
                color: $negro;
                font-size: 0.9rem;
                height: 36px;
                display: flex;
                align-items: center;
            }
            b {
                color: $negro;
                font-size: 0.9rem;
                @include media-970px {
                    margin-top: 12px;
                }
            }
            small {
                color: #118cf1;
                position: relative;
                height: 80px;
                @include media-970px {
                    height: 80px;
                }
            }

            ul {
                position: relative;
                li {
                    color: $negro;
                    font-size: 0.9rem;
                    line-height: normal;
                    text-align: left;
                    font-weight: 400;
                    list-style: none;
                    p {
                        color: $grayBrown;
                        font-size: 0.9rem;
                        line-height: 1.5;
                        text-align: left;
                        font-weight: 300;
                        margin-top: 4px;
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }
    .content__cv__button {
        display: flex;
        justify-content: space-between;
        .button_cv {
            @extend %webapp_button_main;
        }
        .button_cv_back {
            @extend %webapp_button_main;
            background-color: $greenBurn;
            border: 2px solid $greenBurn;
            &:hover {
                border: 2px solid $greenBurn;
                background: none;
                font-weight: 500;
                color: $greenBurn;
            }
            &:active {
                background-color: none;
                transition: 0.1s;
            }
        }
    }
}

.paper {
    padding: 30px 80px;
    @include media-970px {
        padding: 8px;
    }
    @include media-680px {
    }
    @include media-576px {
    }
    @include media-480px {
        margin-top: 20px;
    }
    @include media-380px {
    }
    @include media-280px {
    }
    .freelancer {
        width: 140px;
        height: auto;
        position: relative;
        bottom: 0;
        left: -36px;
        top: 0;
        @include media-680px {
            left: 0;
            width: 110px;
        }
    }
}

.contactme__form {
    padding: 20px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-480px {
        padding: 12px;
    }
    img {
        width: 280px;
        height: auto;
        position: relative;
        margin: 0 auto;
        left: -22px;
        @include media-480px {
            width: 180px;
        }
    }
    h2 {
        text-align: center;
        position: relative;
        font-size: 1.8rem;
        margin-top: 12px;
        margin-bottom: 12px;
    }
    p {
        width: 60%;
        text-align: center;
        font-size: 0.9rem;
        font-weight: 300;
        line-height: 1.6;
        @include media-970px {
            width: 90%;
        }
        @include media-576px {
            width: 100%;
        }
    }
    .divider {
        width: 60%;
        height: 1px;
        background: rgb(184, 26, 184);
        background: linear-gradient(135deg, rgba(184, 26, 184, 1) 37%, rgba(0, 209, 255, 1) 100%);
        top: 10px;
        margin-bottom: 12px;
    }
    #contact-form {
        width: 100%;
        padding: 12px 22px;
        margin-top: 22px;
        position: relative;

        .button_form {
            button {
                @extend %webapp_button_main;
                background-color: #742af7;
                border: 2px solid #742af7;
                width: 100%;
                &:hover {
                    border: 2px solid #742af7;
                    background: none;
                    font-weight: 500;
                    color: #742af7;
                }
                &:active {
                    background-color: none;
                    transition: 0.1s;
                }
            }
        }

        .button_form_block {
            button {
                background-color: #cecece;
                border: 2px solid #cecece;
                padding: 6px 16px 6px 16px;
                border-radius: 5px;
                color: $blanco;
                font-size: 1rem;
                font-weight: 600;
                outline: none;
                text-align: center;
                cursor: pointer;
                box-shadow: 0 0 0 0 rgba(250, 257, 255, 0.7);
                text-decoration: none;
                width: 100%;
            }
        }
        .messageSuccess,
        .messageError {
            text-align: left;
            font-size: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
}

.notFound__content {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
        text-align: center;
        color: $negro;
        top: 1.9rem;
        position: relative;
        font-size: 3rem;
    }

    .illustration {
        width: 50%;
        height: auto;
    }
}
