.timeline {
    position: relative;
    list-style: none;
    padding: 1rem 0;
    margin: 0;
}

.timeline::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    margin-left: -1px;
    background-color: #50a1ff;
}

.timeline-element {
    position: relative;
    width: 50%;
    padding: 1rem 0;
    padding-right: 2.5rem;
    text-align: right;

    p {
        @include media-1024px {
            font-size: 0.8rem;
        }
    }
}

.timeline-element::before {
    content: "";
    position: absolute;
    right: -10px;
    top: 1.35rem;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #50a1ff;
    background-color: #fff;
}

.timeline-element:nth-child(even)::before {
    right: auto;
    left: -8px;
}

.timeline-element:nth-child(even) {
    margin-left: 50%;
    padding-left: 2.5rem;
    padding-right: 0;
    text-align: left;
}

@media (max-width: 767.98px) {
    .timeline::before {
        left: 8px;
    }
}

@media (max-width: 767.98px) {
    .timeline-element {
        width: 100%;
        text-align: left;
        padding-left: 2.5rem;
        padding-right: 0;
    }
}

@media (max-width: 767.98px) {
    .timeline-element::before {
        top: 1.25rem;
        left: 1px;
    }
}

@media (max-width: 767.98px) {
    .timeline-element:nth-child(even) {
        margin-left: 0rem;
    }
}

@media (max-width: 767.98px) {
    .timeline-element {
        width: 100%;
        text-align: left;
        padding-left: 2.5rem;
        padding-right: 0;
    }
}

@media (max-width: 767.98px) {
    .timeline-element:nth-child(even)::before {
        left: 1px;
    }
}

@media (max-width: 767.98px) {
    .timeline-element::before {
        top: 1.25rem;
    }
}
